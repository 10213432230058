@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ccc;
}

#root {
  /* height: 100%; */
  /* width: 300px; */
  /* height: 250px; */

  display: flex;
  flex-direction: column;

  /* padding: 0 5px; */
  background-color: white;
}

body {
  margin: 0;
  font-family:
    'Inter',
    ui-sans-serif,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
